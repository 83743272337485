import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Typography, Card, CardHeader, CardContent, CardActions, Button } from './StyledComponents';
import axios from 'axios';
import { usePreferences } from '../../contexts/Preferences';
import { useAuth } from '../../contexts/AuthContext';

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`;

const DetailedWorkoutCard = styled(Card)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CardHeaderStyled = styled(CardHeader)`
  text-align: left;
`;

const WorkoutSection = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;

const SectionTitle = styled(Typography)`
  color: #3f51b5;
  margin-bottom: 8px;
  text-align: left;
`;

const SectionContent = styled.p`
  margin: 0;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  margin: 0 8px;
`;

const formatText = (text) => {
  if (!text) return '';
  
  // Split text into lines to handle headers separately
  const lines = text.split('\n');
  
  return lines.map((line, lineIndex) => {
    // Check if line is a header
    if (line.startsWith('####')) {
      return (
        <Typography 
          key={`line-${lineIndex}`} 
          variant="subtitle1" 
          style={{ 
            marginTop: '8px', 
            marginBottom: '4px',
            fontSize: '1rem'  // 16px
          }}
        >
          {line.replace(/^####\s*/, '')}
        </Typography>
      );
    } else if (line.startsWith('###')) {
      return (
        <Typography 
          key={`line-${lineIndex}`} 
          variant="subtitle1" 
          style={{ 
            marginTop: '12px', 
            marginBottom: '6px',
            fontSize: '1.1rem'  // ~17.6px
          }}
        >
          {line.replace(/^###\s*/, '')}
        </Typography>
      );
    }
    
    // Handle bold text within regular lines
    const parts = line.split(/(\*\*.*?\*\*)/g);
    const formattedLine = parts.map((part, partIndex) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={`${lineIndex}-${partIndex}`}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
    
    return <React.Fragment key={`line-${lineIndex}`}>{formattedLine}<br /></React.Fragment>;
  });
};

const DetailedWorkoutView = () => {
  const { user } = useAuth();
  const { weekIndex, day, planId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [trainingDay, setTrainingDay] = useState(location.state?.trainingDay || null);
  const trainingPaces = useState(location.state?.trainingPaces || null);
  const [isLoading, setIsLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const isKm = location.state?.isKm;
  const [workoutNotes, setWorkoutNotes] = useState('');

  useEffect(() => {
    const fetchTrainingDay = async () => {
      console.log('fetching training day');

      const week = parseInt(weekIndex) + 1;
      if (user && weekIndex && day && planId) {
        console.log('user', user.username);
        console.log('trainingPaces', trainingPaces);
        try {
          console.log('planId', planId);
          const response = await axios.get(
            `${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/get-training-day`,
            {
                params: {
                    userId: user.username,
                    planId: planId,
                    week,
                    day,
                    trainingPaces,
                }
            }
        );

          console.log('response.data', response.data);
          if (response.status === 200) {
            console.log('response.data', response.data);
            setTrainingDay(response.data);
          }
        } catch (error) {
          console.error('Error fetching training day:', error);
          // You might want to add error handling UI here
        }
      }
    };

    fetchTrainingDay();
  }, [user, weekIndex, day, planId]);

  useEffect(() => {
    if (!trainingDay) return;

    const ws = new WebSocket(
      `${process.env.REACT_APP_GENERATE_TRAINING_PLAN_API_BASE_URL}`
    );

    ws.onopen = () => {
      setNotesLoading(true);
      console.log('WebSocket connection established', trainingDay.workout);
      ws.send(JSON.stringify({
        "action": "notes",
        "workout": trainingDay.workout,
        "trainingPaces": trainingPaces,
        "planId": planId,
        "userId": user.username,
        "week": weekIndex,
        "day": day
      }));
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.content) {
          setWorkoutNotes(prev => prev + data.content);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.onclose = (event) => {
      setNotesLoading(false);
      if (event.wasClean) {
        console.log(`WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`);
      } else {
        console.warn('WebSocket connection abruptly closed');
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      console.log('WebSocket connection closed');
      ws.close();
    };
  }, [trainingDay.workout]);

  const handleClose = () => {
    console.log('planId', planId);
    navigate(`/view-training-plan/${planId}`);
  };

  const handleCompleteWorkout = async () => {
    setIsLoading(true);
    try {
      const saveTrainingPlanResponse = await axios.post(
        `${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/save-training-plan`, {
          userId: user.username,
          planId: planId,
          week: parseInt(weekIndex) + 1,
          day: day,
          completed: true,
          updateOnly: true
      });

      if (saveTrainingPlanResponse.status === 200) {
        setTrainingDay(prev => ({
          ...prev,
          completed: true
        }));
      }
    } catch (error) {
      console.error('Error saving training plan:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const showCompleteButton = React.useCallback(() => {
    return trainingDay && 
      trainingDay.workout && 
      trainingDay.workout !== '' &&
      !trainingDay.workout.toLowerCase().includes('rest') &&
      !trainingDay.workout.toLowerCase().includes('race day');
  }, [trainingDay]);

  return (
    <PageContainer>
      <ScrollableContent>
        <DetailedWorkoutCard>
          <CardHeaderStyled>
            <Typography as="h2" style={{ color: '#3f51b5' }}>
              {trainingDay ? `${trainingDay.day} Detailed Workout` : 'Rest Day'}
            </Typography>
          </CardHeaderStyled>
          <CardContent>
            {trainingDay ? (
              <>
                <WorkoutSection>
                  <SectionTitle as="h3">Workout:</SectionTitle>
                  <SectionContent>{trainingDay.workout}</SectionContent>
                </WorkoutSection>
                {workoutNotes && (
                  <WorkoutSection>
                    <SectionTitle as="h3">Workout Notes:</SectionTitle>
                    <SectionContent>
                      {formatText(workoutNotes)}
                    </SectionContent>
                  </WorkoutSection>
                )}
              </>
            ) : (
              <WorkoutSection>
                <SectionContent>
                  Take this day to rest and recover. Rest days are essential for allowing your body to adapt to training and prevent injury.
                </SectionContent>
              </WorkoutSection>
            )}
          </CardContent>
        </DetailedWorkoutCard>
      </ScrollableContent>
      <CardActions>
        {showCompleteButton() && (
          <ActionButton 
            onClick={handleCompleteWorkout} 
            disabled={trainingDay.completed || isLoading}
          >
            {trainingDay.completed 
              ? 'Workout Completed' 
              : isLoading 
                ? 'Completing...' 
                : 'Complete Workout'}
          </ActionButton>
        )}
        <ActionButton onClick={handleClose}>Close</ActionButton>
      </CardActions>
    </PageContainer>
  );
};

export default DetailedWorkoutView;
