import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import { PreferencesProvider } from './contexts/Preferences';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import PaceConversionChart from './pages/PaceConversionChart';
import PaceCalculator from './pages/PaceCalculator';
import BlogPage from './blogPosts/Blogs';
import BlogPost from './blogPosts/BlogPost';
import TrainingPaceCalculator from './pages/TrainingPacesCalculator';
import PaceEquivalencyCalculator from './pages/EquivelancyCalculator';
import Homepage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import Login from './pages/login/Login';
import Maps from './pages/maps/Maps';
import styled from '@emotion/styled';
import AccountPage from './pages/login/AccountPage';
import { AuthProvider } from './contexts/AuthContext';
import Calculators from './pages/Calculators';
import SavedRoutes from './pages/maps/SavedRoutes';
import TermsAndConditions from './pages/TermsAndConditions';
import EmbedRoute from './pages/maps/EmbedRoute';
import PremiumFeatures from './pages/PremiumFeatures';
import ConsentBanner from './components/ConsentBanner'; // Import the ConsentBanner component
import { HelmetProvider } from 'react-helmet-async';
import HeartRateZoneCalculator from './pages/HeartRateZoneCalculator';
import CalorieBurnCalculator from './pages/CalorieBurnCalculator';
import AITrainingPlanGenerator from './pages/trainingPlanGenerator/AITrainingPlanGenerator';
import TrainingProgress from './pages/trainingPlanGenerator/TrainingProgress';
import DetailedWorkoutView from './pages/trainingPlanGenerator/DetailedWorkoutView';
import ViewTrainingPlans from './pages/trainingPlanGenerator/ViewTrainingPlans';
import TrainingHub from './pages/trainingPlanGenerator/TrainingHub';
import ViewWholeTrainingPlan from './pages/trainingPlanGenerator/ViewWholeTrainingPlan';
import NotFound from './pages/NotFound';
import FiveKTrainingPlan from './pages/trainingPlanGenerator/trainingPlans/5kTrainingPlan';
import TenKTrainingPlan from './pages/trainingPlanGenerator/trainingPlans/10kTrainingPlan';
import HalfMarathonTrainingPlan from './pages/trainingPlanGenerator/trainingPlans/halfMarathonTrainingPlan';
import MarathonTrainingPlan from './pages/trainingPlanGenerator/trainingPlans/marathonTrainingPlan';
import RunTypes from './pages/trainingPlanGenerator/runTypes'
import ViewTrainingPlan from './pages/trainingPlanGenerator/ViewTrainingPlan';
Amplify.configure(awsconfig);

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 56px;

  @media (max-width: 768px) {
    margin-top: 56px;
  }
`;

function App() {
  return (
    <HelmetProvider>
      <PreferencesProvider>
        <AuthProvider>
          <Router>
            <AppContainer>
              <Navbar />
              <ContentContainer>
                <Routes>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/paceConversionChart" element={<PaceConversionChart />} />
                  <Route path="/equivalencyCalculator" element={<PaceEquivalencyCalculator />} />
                  <Route path="/trainingPaceCalculator" element={<TrainingPaceCalculator />} />
                  <Route path="/paceCalculator" element={<PaceCalculator />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/blogs" element={<BlogPage />} />
                  <Route 
                    path="/blogs/:topic" 
                    element={<BlogPage />} 
                  />
                  <Route 
                    path="/blog/:topic/:slug" 
                    element={<BlogPost />} 
                  />
                  <Route 
                    path="/:lang/blog/:topic/:slug" 
                    element={<BlogPost />} 
                  />
                  <Route path="/running-route-planner" element={<Maps />} />
                  <Route path="/success" element={<Maps />} />
                  <Route path="/cancel" element={<Maps />} />
                  <Route path="/calculators" element={<Calculators />} />
                  <Route path="/saved-routes" element={<SavedRoutes />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/embed-route/:routeId" element={<EmbedRoute />} />
                  <Route path="/premium-features" element={<PremiumFeatures />} />
                  <Route path="/heartRateZoneCalculator" element={<HeartRateZoneCalculator />} />
                  <Route path="/calorieBurnCalculator" element={<CalorieBurnCalculator />} />
                  <Route path="/training-hub" element={<TrainingHub />} />
                  <Route path="/view-training-plans" element={<ViewTrainingPlans />} />
                  <Route path="/view-training-plan/:planId" element={<ViewWholeTrainingPlan />} />
                  <Route path="/ai-training-plan-generator" element={<AITrainingPlanGenerator />} />
                  <Route path="/training-progress/:week" element={<TrainingProgress />} />
                  <Route path="/view-training-plan/:planId/workout/:weekIndex/:day" element={<DetailedWorkoutView />} />
                  <Route path="/training-hub/5k-training-plan" element={<FiveKTrainingPlan />} />
                  <Route path="/training-hub/10k-training-plan" element={<TenKTrainingPlan />} />
                  <Route path="/training-hub/half-marathon-training-plan" element={<HalfMarathonTrainingPlan />} />
                  <Route path="/training-hub/marathon-training-plan" element={<MarathonTrainingPlan />} />
                  <Route path="/training-hub/run-types" element={<RunTypes />} />
                  <Route path="/view-training-plan" element={<ViewTrainingPlan />} />
                  <Route path="404" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
              </ContentContainer>
              <ConsentBanner />
            </AppContainer>
          </Router>
        </AuthProvider>
      </PreferencesProvider>
    </HelmetProvider>
  );
}

export default App;
