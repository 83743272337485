/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { getCurrentUser, signUp, signIn, signOut, confirmSignUp, fetchUserAttributes, signInWithRedirect, updateUserAttributes } from 'aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaUser, FaLock, FaEnvelope, FaRunning, FaRoute, FaSave, FaCloudDownloadAlt, FaGoogle } from 'react-icons/fa';
import Account from './AccountPage';
import { useAuth } from '../../contexts/AuthContext';

const containerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
`;

const formStyle = css`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
`;

const inputWrapperStyle = css`
  position: relative;
  margin-bottom: 24px;
`;

const inputStyle = css`
  width: 100%;
  padding: 12px 40px 12px 16px; // Increased right padding to make room for the icon
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
  box-sizing: border-box;

  &:focus {
    border-color: #2a9d8f;
    outline: none;
  }
`;

const iconStyle = css`
  position: absolute;
  right: 16px;
  top: calc(50% + 10px); // Adjusted to center within the input field
  transform: translateY(-50%);
  color: #9e9e9e;
  pointer-events: none;
`;

const buttonStyle = css`
  width: 100%;
  padding: 12px;
  background-color: #2a9d8f;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #238b7e;
  }
`;

const toggleButtonStyle = css`
  background: none;
  border: none;
  color: #2a9d8f;
  cursor: pointer;
  font-size: 14px;
  margin-top: 16px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const errorStyle = css`
  color: #e76f51;
  font-size: 14px;
  margin-top: 8px;
`;

const messageStyle = css`
  color: #2a9d8f;
  font-size: 14px;
  margin-top: 8px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #616161;
`;

const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #2a9d8f;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const pageContainerStyle = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column; // Stack vertically by default

  @media (min-width: 768px) {
    flex-direction: row; // Switch to horizontal layout on larger screens
  }
`;

const formContainerStyle = css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
`;

const landingContainerStyle = css`
  flex: 1;
  background-color: #ffffff;
  padding: 20px; // Reduced padding for mobile
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 40px; // Increase padding on larger screens
  }
`;

const featureStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const featureIconStyle = css`
  font-size: 24px;
  color: #2a9d8f;
  margin-right: 16px;
`;

const featureTextStyle = css`
  font-size: 16px;
  color: #333;
`;

const socialButtonStyle = css`
  width: 100%;
  padding: 12px;
  background-color: #ffffff;
  color: #757575;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  &:hover {
    background-color: #f5f5f5;
  }

  svg {
    margin-right: 8px;
  }
`;

const Login = () => {
  const [formType, setFormType] = useState<'signUp' | 'signIn' | 'confirmSignUp'>('signUp');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useAuth();

  const handleSignUp = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await signUp({
        username,
        password,
        options: {
          userAttributes: { email },
        },
      });
      setFormType('confirmSignUp'); // Move to the confirmation form
      setMessage('Sign up successful. Please check your email for the confirmation code.');
    } catch (error) {
      setError('Error signing up: ' + (error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmSignUp = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await confirmSignUp({
        username,
        confirmationCode
      });
      setMessage('Confirmation successful. You can now sign in.');
      setFormType('signIn');
    } catch (error) {
      setError('Error confirming sign-up: ' + (error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await signIn({ username, password });
      const currentUserAttributes = await fetchUserAttributes();
      setUser({
        username,
        email: currentUserAttributes.email || '',
        isPremium: currentUserAttributes['custom:premium'] === 'true'
      });
      if (location.state?.from) {
        if (location.state.from === '/running-route-planner') {
          navigate(location.state.from, { state: { savingRoute: true } });
        } else {
          navigate(location.state.from);
        }
      } else {
        navigate('/account');
      }
    } catch (error) {
      setError('Error signing in: ' + (error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    setIsLoading(true);
    try {
      await signOut();
      setUser(null);
      setMessage('Successfully signed out.');
    } catch (error) {
      setError('Error signing out: ' + (error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUsername = async (newUsername: string) => {
    setIsLoading(true);
    try {
      // Update the preferred_username attribute
      await updateUserAttributes({
        userAttributes: {
          'preferred_username': newUsername
        }
      });
      
      // Update local user state
      setUser(prev => prev ? {
        ...prev,
        username: newUsername
      } : null);
      
      setMessage('Username updated successfully');
    } catch (error) {
      setError('Error updating username: ' + (error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithRedirect({
        provider: 'Google'
      });
      // After successful sign-in, check user attributes
      const currentUserAttributes = await fetchUserAttributes();
      const defaultUsername = currentUserAttributes.email?.split('@')[0] || '';
      
      // If no preferred_username is set, set it to the email prefix
      if (!currentUserAttributes.preferred_username) {
        await handleUpdateUsername(defaultUsername);
      }

      setUser({
        username: currentUserAttributes.preferred_username || defaultUsername,
        email: currentUserAttributes.email || '',
        isPremium: currentUserAttributes['custom:premium'] === 'true'
      });

      // Navigate to the intended destination
      if (location.state?.from) {
        if (location.state.from === '/running-route-planner') {
          navigate(location.state.from, { state: { savingRoute: true } });
        } else {
          navigate(location.state.from);
        }
      } else {
        navigate('/account');
      }
    } catch (error) {
      setError('Error signing in with Google: ' + (error as Error).message);
    }
  };

  if (user) {
    return (
      <Account />
    );
  }

  if (formType === 'confirmSignUp') {
    return (
      <div css={containerStyle}>
        <div css={formStyle}>
          <h2 css={css`text-align: center; color: #333; margin-bottom: 30px;`}>
            Confirm Sign Up
          </h2>
          <form onSubmit={handleConfirmSignUp}>
            <div>
              <Label htmlFor="confirmationCode">Confirmation Code</Label>
              <input
                type="text"
                id="confirmationCode"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
                css={inputStyle}
                autoFocus
              />
            </div>
            <button type="submit" css={buttonStyle} disabled={isLoading}>
              {isLoading ? <LoadingSpinner /> : 'Confirm'}
            </button>
            {error && <p css={errorStyle}>{error}</p>}
            {message && <p css={messageStyle}>{message}</p>}
          </form>
        </div>
      </div>
    );
  }

  return (
    <div css={pageContainerStyle}>
      <div css={formContainerStyle}>
        <div css={formStyle}>
          <h2 css={css`text-align: center; color: #333; margin-bottom: 24px;`}>
            {formType === 'signUp' ? 'Create Account' : 'Welcome Back'}
          </h2>
          {/* <button
            type="button"
            css={socialButtonStyle}
            onClick={handleGoogleSignIn}
            disabled={isLoading}
          >
            <FaGoogle /> Sign in with Google
          </button> */}
          <div css={css`
            text-align: center;
            margin: 16px 0;
            position: relative;
            
            &::before, &::after {
              content: '';
              position: absolute;
              top: 50%;
              width: 45%;
              height: 1px;
              background-color: #e0e0e0;
            }
            
            &::before { left: 0; }
            &::after { right: 0; }
          `}>
            or
          </div>
          <form onSubmit={formType === 'signUp' ? handleSignUp : handleSignIn}>
            <div css={inputWrapperStyle}>
              <Label htmlFor="username">Username</Label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                css={inputStyle}
              />
              <FaUser css={iconStyle} />
            </div>
            <div css={inputWrapperStyle}>
              <Label htmlFor="password">Password</Label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                css={inputStyle}
              />
              <FaLock css={iconStyle} />
            </div>
            {formType === 'signUp' && (
              <div css={inputWrapperStyle}>
                <Label htmlFor="email">Email</Label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  css={inputStyle}
                />
                <FaEnvelope css={iconStyle} />
              </div>
            )}
            <button type="submit" css={buttonStyle} disabled={isLoading}>
              {isLoading ? <LoadingSpinner /> : formType === 'signUp' ? 'Sign Up' : 'Sign In'}
            </button>
            {error && <p css={errorStyle}>{error}</p>}
            {message && <p css={messageStyle}>{message}</p>}
          </form>
          <button
            type="button"
            css={toggleButtonStyle}
            onClick={() => setFormType(formType === 'signUp' ? 'signIn' : 'signUp')}
            disabled={isLoading}
          >
            {formType === 'signUp' ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
          </button>
        </div>
      </div>
      <div css={landingContainerStyle}>
        <h1 css={css`
          font-size: 2rem; // Adjusted font size for mobile
          color: #2a9d8f;
          margin-bottom: 16px;

          @media (min-width: 768px) {
            font-size: 2.5rem; // Larger font size for desktop
            margin-bottom: 24px;
          }
        `}>
          Plan Your Perfect Run
        </h1>
        <p css={css`
          font-size: 1rem; // Adjusted font size for mobile
          color: #555;
          margin-bottom: 24px;

          @media (min-width: 768px) {
            font-size: 1.2rem; // Larger font size for desktop
            margin-bottom: 32px;
          }
        `}>
          Join our community of runners and unlock powerful features to enhance your running experience.
        </p>
        <div>
          <div css={featureStyle}>
            <FaRoute css={featureIconStyle} />
            <span css={featureTextStyle}>Create custom running routes</span>
          </div>
          <div css={featureStyle}>
            <FaSave css={featureIconStyle} />
            <span css={featureTextStyle}>Save and share your custom routes</span>
          </div>
          <div css={featureStyle}>
            <FaCloudDownloadAlt css={featureIconStyle} />
            <span css={featureTextStyle}>Download and upload GPX files</span>
          </div>
        </div>
        <p css={css`font-size: 1.1rem; color: #555; margin-top: 32px;`}>
          Sign up now to start planning your next run and join our community of passionate runners!
        </p>
      </div>
    </div>
  );
};

export default Login;
