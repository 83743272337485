import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import axios from 'axios';
import { getCurrentUser } from 'aws-amplify/auth';
import { useAuth } from '../../contexts/AuthContext';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  background-color: #f5f5f5;
  padding: 10px;
  text-align: center;
  border: 1px solid #e0e0e0;
  font-weight: bold;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #e0e0e0;
  vertical-align: top;
`;

const WeekHeader = styled.th`
  background-color: #e9e9e9;
  padding: 10px;
  text-align: center;
  border: 1px solid #e0e0e0;
  font-weight: bold;
  width: 100px;
`;

const ClickableTd = styled(Link)<{ $completed?: boolean; }>`
  padding: 10px;
  border: 1px solid #e0e0e0;
  vertical-align: top;
  text-decoration: none;
  color: black;
  display: table-cell;
  cursor: pointer;
  
  ${props => {
    if (props.$completed) {
      return `
        background-color: #e6ffe6;
        &:hover {
          background-color: #d6ffd6;
        }
      `;
    }
  }}
`;

const WorkoutText = styled.div<{ $workoutType?: string }>`
  font-weight: bold;
  color: black;
`;

const StyledButton = styled.button`
  padding: 12px 24px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0052a3;
  }

  &:active {
    background-color: #004080;
  }
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1976d2;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 16px;
`;

interface TrainingDay {
  day: string;
  workout: string;
  distance: string;
  pace: string;
  notes: string;
  estimatedTime: string;
  completed?: boolean;
}

interface TrainingWeek {
  week: string;
  days: TrainingDay[];
}

const ViewWholeTrainingPlan: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { planId } = useParams<{ planId: string }>();
  // const [trainingPlanId, setTrainingPlanId] = useState<string | null>(planId || null);
  const [username, setUsername] = useState<string | null>(null);
  const [trainingPlan, setTrainingPlan] = useState<TrainingWeek[]>(location.state?.trainingPlan || []);
  const [trainingPlanName, setTrainingPlanName] = useState<string | null>(null);
  const [trainingObject, setTrainingObject] = useState<any>(null);
  const [isKm, setIsKm] = useState<boolean>(true);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trainingPaces, setTrainingPaces] = useState<any>(null);

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  useEffect(() => {
    if (username && planId) {
      fetchTrainingPlan();
    }
  }, [username]);

  const fetchTrainingPlan = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/get-training-plan`, {
        params: {
          planId,
          userId: username,
        }
      });
      console.log('response.data', response.data);
      console.log('response.data.paces', response.data.paces);
      setTrainingPaces(response.data.paces);
      
      const transformedPlan = Object.entries(response.data.weeks).map(([weekNum, weekData]: [string, any]) => ({
        week: weekNum,
        days: Object.entries(weekData).map(([day, dayData]: [string, any]) => ({
          day,
          workout: dayData.workout,
          distance: dayData.workout.split(' - ')[1] || '',
          pace: '',
          notes: '',
          estimatedTime: '',
          completed: dayData.completed
        }))
      }));

      setTrainingObject(response.data);
      setTrainingPlan(transformedPlan);
      setTrainingPlanName(response.data.planName);
      setIsKm(response.data.isKm ?? true);
    } finally {
      setIsLoading(false);
    }
  }

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const calculateEstimatedTime = (distance: string, pace: string, notes: string): string => {
    if (!distance || !pace) {
      return '';
    }

    const distanceNum = parseFloat(distance);
    const [minutes, seconds] = pace.split(':').map(Number);
    const paceInMinutes = minutes + seconds / 60;
    
    if (isNaN(distanceNum) || isNaN(paceInMinutes)) {
      return notes ? notes.replace(/^"|"$/g, '') : '';
    }

    const totalMinutes = distanceNum * paceInMinutes;
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = Math.round(totalMinutes % 60);

    return hours > 0 
      ? `${hours}h ${remainingMinutes} minutes`
      : `${remainingMinutes} minutes`;
  };

  const processedTrainingPlan = trainingPlan ? trainingPlan.map(week => ({
    ...week,
    days: week.days.map(day => ({
      ...day,
      estimatedTime: calculateEstimatedTime(day.distance, day.pace, day.notes)
    }))
  })) : [];

  console.log('Processed Training Plan:', processedTrainingPlan);

  return (
    <Container>
      <h1>{trainingPlanName || 'Training Plan'}</h1>
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner />
          <div>Loading your training plan...</div>
        </LoadingContainer>
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <Th>Week</Th>
                {daysOfWeek.map(day => <Th key={day}>{day}</Th>)}
              </tr>
            </thead>
            <tbody>
              {processedTrainingPlan.map((week, weekIndex) => (
                <tr key={weekIndex}>
                  <WeekHeader>{week.week}</WeekHeader>
                  {daysOfWeek.map(day => {
                    const trainingDay = week.days.find(d => d.day === day);
                    console.log('trainingDay', trainingDay);
                    const isLastDay = weekIndex === processedTrainingPlan.length - 1 && day === 'Sunday';
                    const workoutDisplay = isLastDay ? 'Race Day!' : 
                      (trainingDay?.workout === 'Race Day!' ? 'Rest' : (trainingDay?.workout || 'Rest'));
                    
                    return (
                      <ClickableTd
                        key={`${weekIndex}-${day}`}
                        to={`/view-training-plan/${planId}/workout/${weekIndex}/${day}`}
                        state={{ 
                          trainingDay: trainingDay || { day, workout: workoutDisplay },
                          trainingPlan: trainingObject,
                          isKm,
                          weekIndex,
                          trainingPaces
                        }}
                        $completed={trainingDay?.completed}
                      >
                        {trainingDay? (
                          <>
                            <WorkoutText $workoutType={workoutDisplay}>{workoutDisplay}</WorkoutText>
                            <div>{trainingDay.estimatedTime || ''}</div>
                          </>
                        ) : (
                          <WorkoutText>{workoutDisplay}</WorkoutText>
                        )}
                      </ClickableTd>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
          <StyledButton onClick={() => navigate('/view-training-plans')}>
            Back to Training Hub
          </StyledButton>
        </>
      )}
    </Container>
  );
};

export default ViewWholeTrainingPlan;
