// AuthContext.tsx
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

type AuthContextType = {
  user: { username: string; email: string; isPremium: boolean } | null;
  setUser: React.Dispatch<React.SetStateAction<{ username: string; email: string; isPremium: boolean } | null>>;
  isPremium: boolean;
};

type AuthProviderProps = {
    children: ReactNode;
  };
  
  const AuthContext = createContext<AuthContextType | undefined>(undefined);
  
  export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<{ username: string; email: string; isPremium: boolean } | null>(null);
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        const currentUserAttributes = await fetchUserAttributes();
        console.log('currentUserAttributes', currentUserAttributes);
        if (currentUser) {
          setUser({
            username: currentUserAttributes.preferred_username || currentUser.username,
            email: currentUserAttributes.email || '',
            isPremium: currentUserAttributes['custom:premium'] === 'true'
          });
          setIsPremium(currentUserAttributes['custom:premium'] === 'true');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, isPremium }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
